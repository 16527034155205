<template>
  <div class="billing">
    <AccountSlideBar></AccountSlideBar>
    <div class="billing-box">
      <div class="title">
        {{ $t("account_billing") }}
      </div>
      <div class="content">
        <div class="billing-table" v-if="orderList.length">
          <div class="table-row table-head">
            <div class="table-column">
              {{ $t("account_date") }}
            </div>
            <div class="table-column">
              {{ $t("account_amount") }}
            </div>
            <div class="table-column">
              {{ $t("account_payment_method") }}
            </div>
            <div class="table-column">
              {{ $t("account_order") }}
            </div>
          </div>
          <div v-for="order in orderList" :key="order.id" class="table-row">
            <div class="table-column">
              {{ GLOBAL.formatDateByTimestamp(order.createdAt, "Y.m.d h:i:s") }}
            </div>
            <div class="table-column">
              {{ order.amount }}
            </div>
            <div class="table-column">
              {{ order.paymentMethod }}
            </div>
            <div class="table-column">
              {{ order.id }}
            </div>
          </div>
        </div>
        <div class="order-empty" v-else>
          <img
            alt=""
            src="../../assets/image/account_billing_no_order.svg"
            class="no-order-bg"
          />
          <div class="order-empty-tip">{{ $t("account_no_transactions") }}</div>
        </div>
        <div
          class="billing-more btn-cursor"
          v-if="this.currentPage < pageCount"
          @click="getMore"
        >
          {{ $t("account_load_more") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountSlideBar from "../../components/AccountSlideBar.vue";

export default {
  name: "billing",
  components: { AccountSlideBar },
  data() {
    return {
      orderList: [],
      // orderList: [
      //   {
      //     id: 1002,
      //     createdAt: new Date("12/01/2021 18:30:35").getTime() / 1000,
      //     amount: 100,
      //     paymentMethod: "Paypal",
      //   },
      //   {
      //     id: 1002,
      //     createdAt: new Date("12/01/2021 18:30:35").getTime() / 1000,
      //     amount: 100,
      //     paymentMethod: "Paypal",
      //   },
      //   {
      //     id: 1002,
      //     createdAt: new Date("12/01/2021 18:30:35").getTime() / 1000,
      //     amount: 100,
      //     paymentMethod: "Paypal",
      //   },
      // ],
      currentPage: 1,
      perPageCount: 5,
      total: 0,
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(this.total / this.perPageCount);
    },
  },
  methods: {
    getOrderList(limit, page) {
      this.$axios
        .get("/v1/order/list", {
          params: {
            limit: limit,
            page: page,
          },
        })
        .then((response) => {
          console.log(response);
          this.total = response.data.total;
          this.orderList = this.orderList.concat(response.data.data);
        });
    },
    getMore() {
      this.currentPage++;
      this.getOrderList(this.perPageCount, this.currentPage);
    },
  },
  created() {
    this.getOrderList(this.perPageCount, this.currentPage);
  },
};
</script>

<style scoped>
.billing {
  min-height: 100vh;
  padding-left: 230px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  background-color: #f8f8f8;
  overflow: scroll;
}

@media screen and (max-width: 1024px) {
  .billing {
    padding-left: 65px;
  }
}

.billing-box {
  margin-top: 35px;
  padding: 40px 16px;
}

.title {
  font-size: 30px;
  font-weight: bold;
  line-height: 37px;
  color: #1d1d1d;
}

.content {
  min-width: 760px;
  max-width: 1100px;
  margin-top: 32px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  box-shadow: 0 0 5px rgba(39, 55, 61, 0.05);
  border-radius: 4px;
}

.billing-table {
  color: #707070;
  font-size: 14px;
}

.table-head {
  color: #1d1d1d;
  font-size: 16px;
}

.table-row {
  display: flex;
  align-items: center;
  min-height: 70px;
}

.table-column {
  width: 25%;
  padding: 26px 0 26px 40px;
  box-sizing: border-box;
  border-bottom: 1px solid #f3f3f3;
  height: 100%;
  display: flex;
  align-items: center;
}

.billing-more {
  color: #707070;
  font-size: 14px;
  line-height: 70px;
  text-align: center;
}

.order-empty {
  display: flex;
  height: 486px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #707070;
  font-size: 18px;
}

.no-order-bg {
  max-width: 100%;
  height: auto;
}

.order-empty-tip {
  height: 22px;
  margin-top: 33px 16px 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  color: #707070;
  text-align: center;
}
</style>
